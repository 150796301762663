<template>
  <div>
    <Modal :options="{width: '30vw',esc:false,type:'secondary'}">
      <form @submit.prevent="$emit('update',usuario_tmp)">
        <div class="title">Usuario</div>
        <div class="body">
          <div class="row form-group">
            <label for="" class="col-form-label col-sm-12 col-lg-3">Tipo usuario</label>
            <div class="col-sm-12 col-lg-9">
              ¿El usuario existe en Account? 
              <BPBSwitch v-model="tipo_usuario" texton="Sí" textoff="No"/>
            </div>
          </div>
          <hr v-if="tipo_usuario">
          <div v-if="tipo_usuario" class="row form-group">
            <label for="buscar_usuario" class="col-form-label col-sm-12 col-lg 3">Buscar usuario</label>
            <div class="col-sm-12 col-lg-9"><input v-model="buscar_usuario_text" type="text" name="buscar_usuario" id="buscar_usuario" class="form-control"></div>
          </div>
          <div v-if="tipo_usuario" class="row form-group">
            <label for="account_id" class="col-form-label col-sm-12 col-lg-3">Usuarios</label>
            <div class="col-sm-12 col-lg-9">
              <select v-model="account_id" name="account_id" id="account_id" class="form-control">
                <option v-for="usuario in usuarios_filtrados" :value="usuario.id">{{ usuario.nombre }}</option>
              </select>
            </div>
          </div>
          <hr v-if="tipo_usuario">
          <div class="row form-group">
            <label for="nombre" class="col-form-label col-sm-12 col-lg-3">Nombre</label>
            <div class="col-sm-12 col-lg-9"><input v-model="usuario_tmp.nombre" type="text" name="nombre" id="nombre" class="form-control"></div>
          </div>
          <div class="row form-group">
            <label for="email" class="col-form-label col-sm-12 col-lg-3">Email</label>
            <div class="col-sm-12 col-lg-9"><input v-model="usuario_tmp.email" type="email" name="email" id="email" class="form-control"></div>
          </div>
          <div v-if="false" class="row form-group">
            <label for="obligatorio" class="col-form-label col-sm-12 col-lg-3">Obligatorio</label>
            <div class="col-sm-12 col-lg-9">
              <select v-model="usuario_tmp.obligatorio" name="obligatorio" id="obligatorio" class="form-control">
                <option value="si">Sí</option>
                <option selected value="no">No</option>
              </select>
            </div>
          </div>
          <div class="row form-group">
            <label for="estatus" class="col-form-label col-sm-12 col-lg-3">Estatus</label>
            <div class="col-sm-12 col-lg-9"><select v-model="usuario_tmp.estatus" name="estatus" id="estatus" class="form-control">
              <option value="activo">Activo</option>
              <option value="inactivo">Inactivo</option>
            </select></div>
          </div>
        </div>
        <div class="footer text-right">
            <button class="btn btn-primary mr-2">Guardar</button>
            <button class="btn btn-danger" type="button" @click="$emit('close')">Cancelar</button>
        </div>
      </form>
    </Modal>
  </div>
</template>

<script>
import Modal from '@/components/Modal';
import BPBSwitch from '@/components/Form/Switch';

import api from '@/apps/analyticpoint/api/comite/alianzas';

export default {
  components: {
    Modal, BPBSwitch
  },
  props: {
    usuario: {
      type: Object,
      default() {
        return {
          account_id: null,
          nombre: null,
          email: null,
          obligatorio: 'no',
          estatus: 'activo'
        }
      }
    },
    index: {
      type: Number
    }
  },
  data() {
    return {
      usuario_tmp: {
        account_id: null,
        nombre: null,
        email: null,
        obligatorio: 'no',
        estatus: 'activo'
      },
      account_usuarios: [],
      tipo_usuario: false,
      account_id: null,
      buscar_usuario_text: null
    }
  },
  mounted() {
    if (this.usuario)
      this.usuario_tmp = this.$helper.clone(this.usuario);
  },
  methods: {
    async obtener_usuarios_account() {
      try {
        this.account_usuarios = (await api.obtener_usuarios_account()).data;
        this.account_id = this.account_usuarios[0].id;
      }catch(e) {
        this.$log.error(e);
        this.$helper.showAxiosError(e,'Error');
      }
    },
    buscar_email(usuario) {
      let email = null;

      usuario.contactos.forEach(contacto => {
        if (contacto.tipo == 'email' && !email) {
          email = contacto.valor;
        }
      });

      return email;
    }
  },
  computed: {
    usuarios_filtrados() {
      if (!this.buscar_usuario_text) {
        if (this.account_usuarios.length > 0)
          this.account_id = this.account_usuarios[0].id;
        return this.account_usuarios;
      }

      let usuarios = [];

      this.account_usuarios.forEach(usuario => {
        if (usuario.nombre.toLowerCase().indexOf(this.buscar_usuario_text.toLowerCase()) > 0)
          usuarios.push(usuario);
      });

      if (usuarios.length > 0)
        this.account_id = usuarios[0].id;

      return usuarios;
    }
  },
  watch: {
    tipo_usuario(val) {
      if (val)
        this.obtener_usuarios_account()
      else
        this.usuario_tmp.account_id = null;
    },
    account_id(val) {
      this.account_usuarios.forEach(usuario => {
        if (usuario.id == val) {
          this.usuario_tmp.account_id = usuario.id;
          this.usuario_tmp.nombre = usuario.nombre;
          this.usuario_tmp.email = this.buscar_email(usuario);
        }
      })
    }
  }
}
</script>